<div class="modal-header">
  <span class="modal-caption">{{ 'warning' | translate }} </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
  </button>
</div>
<div class="modal-body">
  <div class="modal-message">{{ message | translate }}</div>
</div>
<div style="display: none">{{ developerMessage }}</div>
<div style="display: none" class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{ closeBtnName | translate }}</button>
</div>
